import { googleOAuth2 } from "js-utils";
import { Link } from "react-router-dom";
import config from "../config";
import api from "../services/api";

const NavLink = ({
  svg,
  link,
  text,
  setChatLog,
  setChatNames,
  setCurrentUser,
  setChatID,
  setChatName,
  setActiveId,
  setLlmId,
  setLlmLabel,
  setLlmConfig,
  setForm,
  textAreaRef,
}) => {
  const LOGOUT_URL = config.VERSION_ENDPOINT + "auth/logout";
  const DELETE_SESSIONS_ENDPOINT = config.VERSION_ENDPOINT + "chat-session";

  const handleClick = async (text) => {
    if (text === "Clear Conversations") {
      if (
        window.confirm("Are you sure you wish to clear all your conversations?")
      ) {
        api.delete(DELETE_SESSIONS_ENDPOINT).then((response) => {
          setChatLog([]);
          setChatNames([]);
          setChatID("");
          setChatName("");
          setActiveId("");
          setForm(null);
          setLlmId(config.DEFAULT_FUNCTION_ID);
          setLlmLabel(config.DEFAULT_FUNCTION_LABEL);
          setLlmConfig(config.DEFAULT_LLM_CONFIG);
          textAreaRef.current?.focus();
        });
      }
    }
    if (text === "Log out") {
      try {
        setChatLog([]);
        setChatNames([]);
        setCurrentUser(null);
        await googleOAuth2.logout(LOGOUT_URL);
      } catch (error) {
        console.log("Error occurred during logging out", error);
      }
    }
  };

  return (
    <Link
      to={link}
      target={link}
      rel="noreferrer"
      style={{ textDecoration: "none" }}
      onClick={() => handleClick(text)}
    >
      <div className="navPrompt">
        {svg}
        <p>{text}</p>
      </div>
    </Link>
  );
};

export default NavLink;
