import { styles } from "./styles";
import { XIcon } from "./x-icon";
import config from "../../config";

const variantData = {
  "token-limit-warning": {
    emoji: "&#128075;",
    headerText: "Heads Up",
    subText:
      "The more we chat in one session, the more I have to remember (and that costs $$$). Please consider starting a new chat each time the topic changes. That way you won’t need me to recall everything so far.",
  },
  "model-upgraded": {
    emoji: "&#x1F911;",
    headerText: "I hope this pays off!",
    subText:
      "We're using an expanded token limit, and it costs a lot more to remember everything at once. If you need this, keep going and we'll just deduct the extra cost from your paycheck! Or if you're ready, start a new chat.",
  },
  "token-limit-exceeded": {
    emoji: "&#x1F9D0;",
    headerText: "Well that wasn't expected!",
    subText:
      "You've reached the max tokens for this session. You can continue chatting in a new session, or try shortening your prompt",
  },
  "llm-service-error": {
    emoji: "&#129394;",
    headerText: "Trouble Out There",
    subText: (
      <>
        <div id="statusMessage">
          Hey, looks like that LLM service provider is not responding right now.
          Hopefully it is a momentary glitch. Try again in a bit, or mention it
          on{" "}
          <a href={config.SLACK_URL} target="_blank" rel="noreferrer">
            #working-with-ai
          </a>{" "}
          if it keeps happening
        </div>
      </>
    ),
  },
  "generic-error": {
    emoji: "&#x1F9D0;",
    headerText: "Well that wasn't expected",
    subText: (
      <>
        <div id="statusMessage">
          An error occurred. You can try to re-enter your message or start a new
          chat session. If the problem persists please notify{" "}
          <a href={config.SLACK_URL} target="_blank" rel="noreferrer">
            #working-with-ai
          </a>{" "}
          on Slack.
        </div>
      </>
    ),
  },
  "cookies-expired": {
    emoji: "&#x1F9D0;",
    headerText: "Well that wasn't expected",
    subText: "An Error occurred. Try refreshing your page",
  },
  "pdf-parse-error": {
    emoji: "&#129394;",
    headerText: "PDF Parsing Error",
    subText:
      "Seems like we couldn't parse your PDF. You can try again or start a new chat",
  },
};

export const FooterMessage = ({ variant, hideAlert }) => {
  if (!Object.hasOwn(variantData, variant)) {
    return <></>;
  }

  const variantToUse = variantData[variant];
  const { headerText, subText } = variantToUse;

  return (
    <div style={styles.sectionContainer}>
      <div style={styles.outerContainer}>
        <div style={styles.header}>
          <div
            style={styles.emoji}
            dangerouslySetInnerHTML={{ __html: variantToUse.emoji }}
          />
          <div style={styles.textContainer}>
            <strong style={styles.textHeader}>{headerText}</strong>
            <div style={styles.textSubtitle}>{subText}</div>
          </div>
          <button onClick={hideAlert} style={styles.xButton}>
            <XIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
